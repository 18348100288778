import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/index.vue'),
        children: [{
                path: '/',
                name: 'home',
                component: () =>
                    import ('../views/Home.vue'),
                meta: {
                    index: 0
                }
            },
            // 列表页 "catalog": 2,
            {
                path: '/list/:id',
                name: 'list',
                component: () =>
                    import ('../views/list.vue'),
                meta: {
                    index: 2
                }
            },
            // 详情页
            {
                path: '/detail/:parentId/:id',
                name: 'detail',
                component: () =>
                    import ('../views/detail.vue'),
                meta: {
                    index: 2
                }
            },
            // 栏目详情 "catalog": 1,
            {
                path: '/listPage/:id',
                name: 'listPage',
                component: () =>
                    import ('../views/listPage.vue'),
                meta: {
                    index: 1
                }
            },
            // 图片列表 "catalog": 3,
            {
                path: '/listPic/:id',
                name: 'listPic',
                component: () =>
                    import ('../views/listPic.vue'),
                meta: {
                    index: 1
                }
            },
            // 举报
            // {
            //     path: '/report/:id',
            //     name: 'listPic',
            //     component: () =>
            //         import ('../views/report.vue'),
            //     meta: {
            //         index: 1
            //     }
            // },
            // 搜索
            {
                path: '/search/:title',
                name: 'list',
                component: () =>
                    import ('../views/search.vue'),
                meta: {
                    index: 1
                }
            },

        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router